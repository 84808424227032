<template>
    <!-- 分页区域 -->
    <el-pagination
        
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryData.page"
        :page-sizes="[5,10,20,30]"
        :page-size="queryData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>
    <!-- /分页区域 -->
</template>

<script>
export default {
    props:{
        queryData:{
            type: Object,
            required: true  //pagenum当前页码 pagesize每页显示多少条 total总条数
        },
        total:{
            type: Number,
            required: true
        }
    },
    created(){},
    data() {
        return {
        };
    },
    components:{},
    computed:{
    },
    watch:{
    },
    methods: {
        // handleSizeChange这函数是文档自带的里面的设置一个形参val就是所切换的n张为一页的n再把pagesize改为点击的n之后再重新获取数据
        handleSizeChange (val) {
        this.$emit('changepageSize',val)
        //   this.getUserList()
        },
        // handleCurrentChange这函数是文档自带的里面的设置一个形参val就是所点击的页码值再把pagenum改为点击的页码值之后再重新获取数据
        handleCurrentChange (val) {
        this.$emit('changePage',val)
        //   this.getUserList()
        },
    },
};
</script>

<style lang='less' scoped>

</style>
