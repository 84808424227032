<template>
    <div>
        <q-dialog
            v-model="checkconfirms"
        >
            <q-card style="width: 700px; max-width: 80vw;">
                <q-card-section class="row items-center q-pb-none">
                    <div class="text-h6">详细信息</div>
                    <q-space />
                    <q-btn icon="close" flat round dense v-close-popup />
                </q-card-section>


                <q-card-section class="q-pt-none scroll" style="max-height: 50vh">
                    <div class='mainbox'>
                        <div class="leftbox">
                            <div class='leftmainbox' v-for='(item,index) in leftlogListCheckColumn' :key="index">
                                <div class='titbox'>
                                    {{item.label}}:
                                </div>

                                <div v-if="item.chip" class="inputbox">
                                    <q-chip  square :color="checkDatas[item.value] =='成功'?'positive':'red'" text-color="white">
                                        {{ checkDatas[item.value] }}
                                    </q-chip>
                                    
                                </div>
                                <div class="inputbox" v-else-if="item.input">
                                    <q-input
                                        style='width: 100%;'
                                        dense
                                        v-model="checkDatas[item.value]"
                                        outlined 
                                        disable 
                                        autogrow
                                    />
                                </div>
                                <span v-else class="inputbox" style="word-wrap: break-word; word-break: break-all;">
                                    {{`${checkDatas[item.value]}`}}
                                </span>
                            </div>
                        </div>
                        
                        <div class='rightbox'>
                            <div class='leftmainbox' v-for='(item,index) in rightlogListCheckColumn' :key="index">
                                <div class='titbox'>
                                    {{item.label}}:
                                </div>

                                <div v-if="item.chip" class="inputbox">
                                    <q-chip  square :color="checkDatas[item.value] =='成功'?'positive':'red'" text-color="white">
                                        {{ checkDatas[item.value] }}
                                    </q-chip>
                                    
                                </div>
                                <span v-else class="inputbox">
                                    {{`${checkDatas[item.value]}`}}
                                </span>
                            </div>
                        </div>
                        
                    </div>
                    
                    
                </q-card-section>

                <q-card-actions align="center" class="bg-white text-teal">
                    <q-btn color="primary" label="确定" v-close-popup />
                </q-card-actions>
            </q-card>
        </q-dialog>
    </div>
</template>
<script>
export default {
    props:{
        checkconfirm: {
            type: Boolean,
            required: true,
        },
        checkData: {
            type: Object,
            required: true,
        },
        //左侧循环信息
        leftlogListCheckColumn:{
            type: Array,
            required: true,
        },
        //右侧循环信息
        rightlogListCheckColumn:{
            type: Array,
            required: true,
        }
    },
    data() {
        return {
        }
    },
    created(){
    },
    computed:{
        checkconfirms:{
            get(){
                return this.checkconfirm
            },
            set(v){
                this.$emit('update:checkconfirm', v)
            }
        },
        checkDatas:{
            get(){
                return this.checkData
            },
            set(v){
                this.$emit('update:checkData', v)
            }
        }
    },
    methods:{
    },
    }
</script>
<style lang="less" scoped>
.mainbox{
    display: flex;
    justify-content: space-between;
    .leftbox{
        margin: 0 5px;
        width: 50%;
      
    }
    .rightbox{
        width: 50%;
    }
    .leftmainbox{
        display: flex;
        flex-direction : row;
        margin: 8px 0;
        .titbox{
            width: 20%;
            font-size: 14px;
            font-weight: bold;
            max-width: 100px;
        }
        .inputbox{
            // width: 100px;
            display: flex;
            flex-wrap: wrap;
            width: 80%;
        }
    }
}
</style>
