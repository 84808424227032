<template>
    <div>
        <!-- 列表的表头循环 -->
        <template v-for='(item, index) in listTableColumn'>
            <el-table-column 
                v-if="item[item.prop]"
                :key='index'
                show-overflow-tooltip
                sortable="custom" 
                align='center' 
                :prop="item.prop" 
                :label="item.label" 
                :min-width="item.minWidth"
            >
                <template slot-scope="{row}">
                    <!-- {{row.orderUrgent}} -->
                    <q-chip v-if="item.chip" square text-color="white" :color="item.chipColor ? item.chipColor[row[item.prop]]: 'primary' ">
                        {{ (( $store.state[item.chipValue]).filter(item=>item.key == row[item.prop]).pop() || {} ).value }}
                    </q-chip>
                    <span v-else-if="item.nochip">
                        {{ (( $store.state[item.chipValue]).filter(item=>item.key == row[item.prop]).pop() || {} ).value}}
                        <!-- {{row[item.prop]}} -->
                    </span>
                    <span v-else>
                        {{row[item.prop]}}
                    </span>
                </template>
            </el-table-column>
        </template>
        
    </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
    props:{
        listTableColumn:{
            type: Array,
            required: true
        },
       
    },
    data() {
        return {
        }
    },
    created(){
    },
    computed:{
        ...mapState([
            'businessStatus', //操作状态
            'businessType', //操作类型
            'moduleName', //模块名称

            'orderStatus', //订单状态
            'orderType',//订单类型
            'payStatus', // 支付状态
            'isPay', //代理商付款状态
            'Permissionslist', // 权限词典
            
            'dashBoardType',
            'userType', 
            'informationStatus', 
            'payChannel', 
            'informationType', 
            'reconStatus', 
            'resultStatus', 
            'apkType', 
            'publishStatus', 
            'publishType', 
            'osType', 
            'discountStatus', 
            'orderUrgent', 
        ]),
        listTableColumns:{
            get(){
                return this.listTableColumn
            },
            set(v){
                this.$emit('update:listTableColumn',v)
            }
        }
    },
    methods:{
    },
}
</script>
<style lang="less" scoped>

</style>
