<template>
    <div id="log" class="q-pa-md q-gutter-sm">
        <!-- 面包屑 -->
        <q-breadcrumbs>
            <q-breadcrumbs-el icon="iconfont icon-zhuye" label="主页" to="/orm/dashboard" />
            <q-breadcrumbs-el label="系统管理" icon="iconfont icon-xitongfuwu" />
            <q-breadcrumbs-el label="操作日志" icon="iconfont icon-xitong-caozuorizhi" to="/order/log" />
        </q-breadcrumbs>
        <!-- 内容区 -->
        <div class='logBox' id='logBox'>
            <!-- 搜索区 -->
            <q-card class="my-card" style="margin-bottom: 10px;">
                <q-card-section>
                    <div class=" searchbox" id="searchbox">
                        <div class="search">
                            <div class="doctorNameBox">
                                <el-input v-model="queryData.operatorName" placeholder="用户名称" clearable 
                                    @clear='getLogList' @blur='getLogList'
                                    :disabled='Permissionslist.filter(item=>item==="log.list").length>0?false:true'
                                >
                                </el-input>
                            </div>
                            <div class="businessTypeBox">
                                <el-select v-model="queryData.businessType" clearable placeholder="请选择操作类型" @change='getLogList'
                                    :disabled='Permissionslist.filter(item=>item==="log.list").length>0?false:true'
                                >
                                    <el-option
                                    v-for="item in businessType"
                                    :key="item.key"
                                    :label="item.value"
                                    :value="item.key"
                                    >
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="businessTypeBox">
                                <el-select v-model="queryData.moduleName" clearable placeholder="请选择模块名称" @change='getLogList'
                                    :disabled='Permissionslist.filter(item=>item==="log.list").length>0?false:true'
                                >
                                    <el-option
                                    v-for="item in moduleName"
                                    :key="item.key"
                                    :label="item.value"
                                    :value="item.key"
                                    >
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="startendBox">
                                <el-date-picker
                                    value-format='yyyy-MM-dd'
                                    style="width: 100%;"
                                    v-model="startendTime.beginEndDate"
                                    type="daterange"
                                    range-separator=":"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    @change='dateChange'
                                    :disabled='Permissionslist.filter(item=>item==="log.list").length>0?false:true'
                                >
                                </el-date-picker>
                            </div>
                        </div>

                        <!-- <div class="addbtn">
                            <el-button class="reportbtn" round size="mini" type="primary" plain icon="el-icon-download" @click='reportForm'>导出</el-button>

                            <morefields
                                ref='morefields'
                                :checkList.sync='checkList'
                            />
                            更多筛选
                            <morePopover 
                                :form.sync='queryData'
                                :startendTime.sync='startendTime'
                                :china='china'
                                @getreportList='getreportList'
                                @reportForm='reportForm'
                            />
                        </div> -->
                    </div>
                </q-card-section>
            </q-card>
            <!-- 列表区域 -->
            <!-- 
                v-loading="ordervisible"
                element-loading-text="拼命加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(55,55,55,0.2)"
                :max-height="tableHeight"
                @sort-change="sortthiscolumn"
             -->
            <q-card class="my-card">
                <q-card-section>
                    <el-table
                        ref="multipleTable"
                        border
                        stripe
                        :data="logAllList"
                        tooltip-effect="dark"
                        style="width: 100%"
                        v-loading="ordervisible"
                        element-loading-text="拼命加载中"
                        element-loading-spinner="el-icon-loading"
                        element-loading-background="rgba(55,55,55,0.2)"
                        @sort-change="sortthiscolumn"
                        :max-height="tableHeight"
                    >
                        <template slot="empty">
                            {{nodatetype}}
                        </template>

                        <el-table-column align='center'  label="操作">
                            <template slot-scope="{row}">
                                <el-button type="text" @click="detailedData(row)">详细信息</el-button>
                            </template>
                        </el-table-column>
                        
                        <tableColumn
                            :listTableColumn.sync="listTableColumn"
                        />

                        

                    </el-table>
                    <!-- 分页组件 -->
                    <elementPagination class="Paginationstyle" :total='queryData.total' :queryData='queryData' @getList='getLogList()' @changepageSize='changepageSize' @changePage='changePage'/>
                </q-card-section>
            </q-card>
        </div>
        
        <checkOrderLlog
            :checkconfirm.sync='checkconfirm'
            :checkData='checkData'
            :logListCheckColumn='logListCheckColumn'
        />
    </div>
</template>
<script>
import { mapState } from 'vuex'
import elementPagination from '@/components/ElementUI/elementPagination.vue'; // 分页组件
import tableColumn from '@/components/ElementUI/tableColumn.vue'; // 列表表头
import checkOrderLlog from '@/components/OrderLlog/checkOrderLlog.vue'; // 详细

import { orderlLog } from '@/api/OrderLlog.js'
import { getHeight } from '@/api/getHeight.js'; // 获取列表最高高度

export default {
    data() {
        return {
            nodatetype:'',
            ordervisible: false,//控制列表加载画面
            tableHeight:500, //列表最大高度
            // 分页设置
            queryData:{ // element分页信息
                page: 1,
                pageSize: 5,
                total:0,
                operatorName:'', //用户名
                beginDate:'', //订单开始日期
                endDate:'', //订单结束日期
                businessType: '', //操作类型
                moduleName: '', //模块名称
                sortType:'', //排序类型
                sortField:'', //顺序字段
            },
            startendTime:{
                beginEndDate:'', //订单开始和结束日期
            },
            checkList:{
                moduleName: true, //模块名
                businessType: true, //操作类型
                operatorContent: true, //操作内容
                operatorName: true, //操作人
                modifyTime: true, //操作时间
                operatorStatus: true, //操作状态
            },
            // 所有日志列表
            logAllList:[],

            // 控制详细弹框
            checkconfirm: false,
            checkData: {}, 
            /**
             * prop 列表prop
             * label 列表label
             * checkValue: 更多筛选中字段名称
             * orderId: 更多筛选中控制字段名称
             * chip: q-chip是否显示
             * nochip: 不显示chip但是需要在vuex中查找
             * chipColor: chip的颜色选择
             * chipValue:  chip中的所需要的filter 内容
             * urgenthide: 加急猎头是否显示 默认隐藏
             * minWidth: 最小宽度
             * hide: 所有表头控制显示
             */
            listTableColumn:[
                {label:'模块名',prop:'moduleName',moduleName: true,minWidth:'104px',nochip: true,chipValue:'moduleName'},
                {label:'操作类型',prop:'businessType',businessType: true,minWidth:'104px',nochip: true,chipValue:'businessType'},
                {label:'操作内容',prop:'operatorContent',operatorContent: true,minWidth:'104px'},
                {label:'操作人',prop:'operatorName',operatorName: true,minWidth:'104px'},
                {label:'操作时间',prop:'modifyTime',modifyTime: true,minWidth:'104px'},
                {label:'操作状态',prop:'operatorStatus',operatorStatus: true,minWidth:'104px',nochip: true,chipValue:'businessStatus'},
            ],

            
            logListCheckColumn:[
                {label:'系统类型',value:'operatorSystem'},
                {label:'模块名称',value:'moduleName'},
                {label:'操作类型',value:'businessType'},
                {label:'操作内容',value:'operatorContent'},
                {label:'操作用户',value:'operatorName'},
                {label:'操作状态',value:'operatorStatus'},
                {label:'错误消息',value:'operatorError'},
                {label:'创建时间',value:'createTime'},
                {label:'修改时间',value:'modifyTime'},
                {label:'请求方式',value:'requestMode'},
                {label:'请求方法',value:'requestMethod'},
                {label:'请求URL',value:'requestUrl'},
                {label:'请求参数',value:'requestParam'},
                {label:'请求结果',value:'requestResult'},
                
            ],
            // 操作状态
            businessStatusColor:{
                0: 'positive',
                1: 'red',
            },
        }
    },
    created(){
        this.getLogList()
    },
    components:{
        checkOrderLlog,
        elementPagination,
        tableColumn
    },
    computed:{
        ...mapState([
            'businessStatus', //操作状态
            'businessType', //操作类型
            'moduleName', //模块名称
            'Permissionslist', // 权限词典
        ])
    },
    methods:{
        //获取报表列表
        getLogList(){
            this.nodatetype=''
            this.ordervisible = true
            setTimeout(async() =>{
                const { data:res } = await orderlLog({
                    ...this.queryData,
                })
                console.log('日志列表',res);
                if(res.code===20000){
                    this.logAllList = res.data.logs
                    this.queryData.total=res.data.totals*1

        
                    let height = document.getElementById('searchbox').offsetHeight + 80
                    this.tableHeight = getHeight('logBox',height)

                    this.ordervisible = false
                    this.nodatetype='暂无数据'
                }else{
                    this.ordervisible = false
                    this.nodatetype='数据获取失败'
                }
            },1000)
        },
        //排序
        sortthiscolumn(column){
            if(column.order=='ascending'){
                // ascending 为升序
                this.queryData.sortField = column.prop
                this.queryData.sortType = 1
            }else if(column.order=='descending'){
                // ascending 为降序
                this.queryData.sortField = column.prop
                this.queryData.sortType = 0
            }else{
                // 默认按照时间排序排序
                this.queryData.sortField = 'createTime'
                this.queryData.sortType = 0
            }
            this.getLogList()
        },
        // 日期获取
        dateChange(v){
            this.queryData.beginDate = this.startendTime.beginEndDate ?this.startendTime.beginEndDate[0] :''
            this.queryData.endDate = this.startendTime.beginEndDate?this.startendTime.beginEndDate[1] :''
            this.getLogList()
        },
        //详细信息
        detailedData(row){
            console.log(row);
            this.checkData = JSON.parse(JSON.stringify(row))
            // 模块名称匹配
            this.checkData.moduleName = (this.moduleName.filter(item=>item.key == row.moduleName).pop() || {} ).value
            // 操作状态
            this.checkData.operatorStatus = (this.businessStatus.filter(item=>item.key == row.operatorStatus).pop() || {} ).value
            // 操作类型
            this.checkData.businessType = (this.businessType.filter(item=>item.key == row.businessType).pop() || {} ).value

            this.checkconfirm = true
        },
        // 分页发送变化函数
        changepageSize(val){
            this.queryData.pageSize = val
            this.getLogList()
        },
        changePage(val){
            this.queryData.page = val
            this.getLogList()
        },
    },
}
</script>
<style lang="less" scoped>
#log{
  height: 100%;
}
.logBox{
  height: 90%;
}
.searchbox{
    display: flex;
    justify-content: flex-start;
    .search{
        display: flex;
        flex-direction: row ;
        //align-items: flex-end;
        align-items: center;
        flex-wrap: wrap;
        .doctorNameBox{
            width: 150px;
            margin: 0 10px;
        }
        .startendBox{
            width: 250px;
            margin: 0 10px;
        }
        .businessTypeBox{
            width: 150px;
            margin: 0 10px;
        }
        .operation{
            // margin: .625rem .625rem 0 .625rem;
            margin: 0 .625rem 0 .625rem;
            width: 12.5rem;
        }
    }
}
/deep/.el-table td{
  padding: 8px 0 !important;
}
.Paginationstyle{
  margin-top: .9375rem;
}
</style>
