<template>
    <div id="app" class="box" @click="clickDiv">
        <router-view></router-view>
    </div>
</template>

<script>
import { getItem, removeItem } from '@/utils/storage.js';
export default {
    data() {
        return {
            step: 1,
            lastTime: null, // 最后一次点击的时间
            currentTime: null, // 当前点击的时间
            timeOut: 300 * 60 * 1000, // 设置超时时间:10分钟
            timeInterval: "",
        };
    },
    components: {},
    methods: {
        clickDiv() {
            if (this.timeInterval == "") {
            // 5秒钟检测一次
                this.timeInterval = setInterval(this.isTimeOut, 5000);
            }
            this.lastTime = new Date().getTime();
        },
        isTimeOut() {
            this.currentTime = new Date().getTime(); // 当前时间
            // 判断上次最后一次点击的时间和这次点击的时间间隔是否大于规定的时间,例:10分钟
            if (this.currentTime - this.lastTime > this.timeOut) {
                //判断是否为登录状态,只有登录状态下才调用方法
                const token = getItem('token')
                if (token) {
                    removeItem('token')
                    clearInterval(this.timeInterval);
                    this.timeInterval = "";
                    this.$router.push('/login');
                    //先返回首页,在给提示,可以根据具体需求调整
                    this.$q.dialog({
                        title: "提示",
                        message: '检测到您长时间未操作页面,请重新登录!',
                        ok: { label: '确定'},
                    })
                    .onOk(() => {});
                }
            }
        },
    }
};
</script>

<style lang="less">

.el-popover{
  min-height: 50px !important;
  max-height: 200px !important;
  padding: 5px 10px !important;
  overflow: auto;
}

// 院内信息弹出框
.popoverBackB{
    background: #409EFF !important;
    // z-index: 9999 !important;
    border: none !important;
    color: #fff !important;
    overflow: initial !important;

    // overflow: hidden;
    .popper__arrow::after{
        border-bottom-color: #409EFF !important;
    }
}
//更多筛选弹出框
.morePopover{
    padding: 10px!important; 
    max-height: 500px !important;
     // z-index: 7000 !important;  
    .el-menu {
        border-right: none !important;
        .el-menu-item-group__title {
            padding: 0;
        }
        .el-menu-item{
            height: 35px;
            line-height: 35px;
        }
       
    }
}

// 站内消息弹出框
.fetchNoReadNum{
    padding: 5px!important; 
    max-height: 500px !important;
    .tabbox{
        // width: 100%;
        .maxh{
            min-height: 6.25rem;
            max-height: 300px;
            overflow: auto;
            .read{
                border: 1px solid #DCDFE6;
                padding: 10px!important;
                margin-bottom: 3px !important;
                .tit{
                    height: 25px;
                    line-height: 25px;
                    display: flex;
                    justify-content: space-between;
                    font-size: 16px;
                    // font-weight: bold;
                    color: #262525;
                }
                .main{
                    font-size: 16px;
                    color: #262525;

                    // font-weight: bold;
                }
                .time{
                    font-size: 14px;
                    // font-weight: bold;
                    color: #a4a6a9;
                }
                .delbtn{
                    display: none;
                    padding: 3px;
                }
            }
        }
        .nomessage{
            min-height: 6.25rem;
            max-height: 300px;
            text-align: center;
            line-height: 6.25rem;
        }
    }

    .loginOut{
        position: absolute;right:1.25rem;top:.625rem;
        color: #e6a23c;
        font-weight: 600;
        font-size: 14px;
    }
    .pagination{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.box {
    height: 100vh;
}



::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
::-webkit-scrollbar-track {
    /* border-radius: 3px; */
    background: rgba(255, 255, 255, 0.3);
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.08);
}
::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
}
/* 消除type number 调节小箭头 */
input[type=number] {
    -moz-appearance:textfield;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
</style>
