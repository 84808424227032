//成功提示
import Quasar from 'quasar/src/vue-plugin.js';import Dialog from 'quasar/src/plugins/Dialog.js';import Notify from 'quasar/src/plugins/Notify.js';
export const notifys = (state,message)=>{
    // window.$q.notify({
    //     type: state,
    //     position: "top",
    //     message: message
    // })
    Notify.create({
        type: state,
        position: "top",
        message: message,
        timeout:1000
    })
}

//灰色提醒