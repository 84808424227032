import axios from 'axios'
import { getItem } from "../utils/storage"
import store from '../store'
import { notifys } from '@/api/components/Notify.js'

// create an axios instance
const instance = axios.create({
    baseURL: '/', // api的base_url
})
var config = true
// request interceptor
instance.interceptors.request.use(
    config => {
        const token = getItem("token")
        // console.log(config);
        // 接口文档中 写的是参数名字为token  就是config.headers.token = token
        if (token) {
            config.headers.token = token
        }
        return config
    },
    error => {
        console.log(error)
        Promise.reject(error)
    }
)
// 判断如果接口返回数据为200002 就弹出提示重新登陆
instance.interceptors.response.use(response => {
    // console.log(response);
    if (response.data && typeof response.data === 'object') {
        if (response.data.code == 20002) {
            if (sessionStorage.getItem("token") != null) {
                window.vue.$q
                .dialog({
                    title: "提示",
                    // message: response.data.message,
                    message: '登录信息失效，请重新登陆',
                    ok: { label: '确定'},
                })
                .onOk(() => {});

                sessionStorage.removeItem("token")
                window.vue.$router.push('/login')
            }else{
                // window.vue.$q.
                // notify({
                //     type: 'negative',
                //     position:'top-right',
                //     message: '登录信息失效，请重新登陆'
                // })
                notifys('negative','登录信息失效，请重新登陆')
                window.vue.$router.push('/login')
            }
            return
        }
    }
    return response
}, error => {

})

export const createAPI = (url, method, data, config = {}) => {
    if (method === 'get') {
        config.params = data
    } else {
        config.data = data
    }
    return instance({
        url,
        method,
        ...config
    })
}