import Vue from 'vue'
import 'element-ui/lib/theme-chalk/index.css'
import {
    Cascader,
    Table,
    TableColumn,
    Pagination,
    Dialog,
    Select,
    Option,
    Tree,
    Container,
    Header,
    Aside,
    Main,
    Menu,
    Submenu,
    MenuItemGroup,
    MenuItem,
    Button,
    DatePicker,
    Upload,
    Popover,
    Loading,
    Tabs,
    TabPane,
    Switch,
    Timeline,
    TimelineItem,
    Tag,
    FormItem,
    Input,
    Form,
    DropdownItem,
    DropdownMenu,
    Dropdown,
    Checkbox,
    Descriptions,
    DescriptionsItem,
    Badge,
    Avatar,
} from 'element-ui'

// import * as element from 'element-ui'

// console.log(element);
Vue.use(Cascader)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Pagination)
Vue.use(Dialog)
Vue.use(Select)
Vue.use(Option)
Vue.use(Tree)
Vue.use(Container)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItemGroup)
Vue.use(MenuItem)
Vue.use(Button)
Vue.use(DatePicker)
Vue.use(Upload)
Vue.use(Popover)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Switch)
Vue.use(Timeline)
Vue.use(TimelineItem)
Vue.use(Tag)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Form)
Vue.use(DropdownItem)
Vue.use(DropdownMenu)
Vue.use(Dropdown)
Vue.use(Checkbox)
Vue.use(Descriptions)
Vue.use(DescriptionsItem)
Vue.use(Badge)
Vue.use(Avatar)

Vue.use(Loading)
