import Vue from 'vue'
import Vuex from 'vuex'

// import { setItem, getItem } from "@/utils/storage.js"
// import jwt from 'jsonwebtoken'

Vue.use(Vuex)

const token = 'token'
export default new Vuex.Store({
  state: {
    userNickName:'',
    Permissionslist:[], // 权限管理权限

    dashBoardType:[], // 主页省份医院 月份 统计选项
    userType:[], //用户类型
    orderType:[], //订单类型
    informationStatus:[],//资料审核状态
    orderStatus:[], //订单状态
    orderCheckStatus:[], //订单审核状态
    payChannel:[], //线下还是线上  支付方式
    payStatus:[], //待支付还是已支付  支付状态
    informationType:[],//资料类型 0-检查报告  1-影像资料
    isPay:[
      {key: true,value: '已收款'},
      {key: false,value: '未收款'}
    ],
    reconStatus:[], // 三维重建状态
    resultStatus:[], //重建结果状态
    permissionType:[], //资源管理类型

    apkType:[], //应用名称
    publishStatus:[], //发布状态
    publishType:[], //发布类型
    osType:[], //系统类型
    discountStatus:[], //优惠码使用状态
    orderUrgent:[], //加急状态
    
    businessStatus:[],//操作状态
    moduleName:[], //模块名称
    businessType:[],//操作类型

    phoneconfirm: false, //控制登录页面绑定手机号弹窗
    phonetoken: '', //未绑定手机号实返回的token值
  },
  mutations: {
    getuserNickName(state,n){
      state.userNickName = n //用户信息
    },
    getPermissionslist(state,n){
      state.Permissionslist = n //权限词典
    },
    getuserType(state,n){
      state.userType = n //订单类型
    },
    getorderType(state,n){
      state.orderType = n //订单类型
    },
    getinformationStatus(state,n){
      state.informationStatus = n //资料审核信息
    },
    getorderStatus(state,n){
      state.orderStatus = n
    },
    getorderCheckStatus(state,n){
      state.orderCheckStatus = n
    },
    getpayChannel(state,n){
      state.payChannel = n
    },
    getpayStatus(state,n){
      state.payStatus = n
    },
    getinformationType(state,n){
      state.informationType = n // 资料类型 0-检查报告  1-影像资料
    },
    getreconStatus(state,n){
      state.reconStatus = n 
    },
    getresultStatus(state,n){
      state.resultStatus = n 
    },

    getpermissionType(state,n){
      state.permissionType = n 
    },

    editphoneconfirm(state,n){
      state.phoneconfirm = n // 修改登录页面绑定手机号弹窗状态
    },
    getphonetoken(state,n){
      state.phonetoken = n 
    },
    getdashBoardType(state,n){
      state.dashBoardType = n // 修改主页月统计图省份医院 月份返回值
    },

    getapkType(state,n){
      state.apkType = n // 修改用户名称
    },
    getpublishStatus(state,n){
      state.publishStatus = n // 修改发布状态
    },
    getpublishType(state,n){
      state.publishType = n // 发布类型
    },
    getosType(state,n){
      state.osType = n // 系统类型
    },
    getdiscountStatus(state,n){
      state.discountStatus = n // 优惠码使用状态
    },
    getorderUrgent(state,n){
      state.orderUrgent = n // 加急状态
    },
    getbusinessStatus(state,n){
      state.businessStatus = n   //操作状态
    },
    getbusinessType(state,n){
      state.businessType = n  //操作类型
    },
    getmoduleName(state,n){
      state.moduleName = n  //模块名称
    },
  },
  actions: {
  },
  modules: {
  }
})
