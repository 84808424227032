/**
 * 封装localStorage中获取 储存和删除数据
 */


/**
 * 储存数据到本地
 * @param {*} key 需要储存的key
 * @param {*} value 需要储存的value
 */
 export const setItem = (key, value) => {
    if (typeof value === 'object') {
      value = JSON.stringify(value)
    }
    sessionStorage.setItem(key, value)
  }


  /**
   * 从本地获取数据
   * @param {*} key 需要获取的key
   */
  export const getItem = key => {
    // 利用lacalStorage.getItem 获取所需要的值
    const data = sessionStorage.getItem(key)
    try {
      // 利用JSON.parse的方法判断获得的值是否是JOSN格式的字符串如果是 就转换成对象或数据在return
      return JSON.parse(data)
    } catch {
      // 如果不是就会来到catch区直接return data
      return data
    }
  }

    
  /**
   * @param {*} key 需要移除的key
   */
  export const removeItem = key => {
    sessionStorage.removeItem(key)
  }