import Vue from 'vue'
import VueRouter from 'vue-router'
import { getItem } from "../utils/storage"
import Test from '../views/Test.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('../views/Login.vue')
  },
  { path: '/login', name: 'login', component: () => import('../views/Login.vue'), },
  { path: '/wechartLogin', component: () => import('../views/wechartLogin.vue') },
  { path: "/viewer", component:  () => import("../views/3D/Viewer.vue")},
  {
    path: '/home', name: 'home', component: () => import('../views/Home.vue'), redirect: '/orm/dashboard',
    children: [
      { path: "/orm/dashboard", name: '/orm/dashboard', component: () => import("../views/OrmDashboard/index.vue") }, // 系统主页
      { path: "/user/list", name: '/user/list', component: () => import("../views/Users/index.vue") }, // 用户管理
      { path: "/role/list", name: '/role/list', component: () => import("../views/Role/index.vue") }, // 角色管理
      { path: "/permission/list", name: '/permission/list', component: () => import("../views/Permission/index.vue") }, // 资源管理
      { path: "/group/list", name: '/group/list', component: () => import("../views/Group/index.vue") }, // 用户组管理
      { path: "/order/list", name: '/order/list', component: () => import("../views/Order/index.vue") }, // 用户订单
      { path: "/order/check/list", name: '/order/check/list', component: () => import("../views/OrderCheck/index.vue") }, // 订单审核
      { path: "/information/list", name: '/information/list', component: () => import("../views/Information/index.vue") }, // 资料审核
      { path: "/order/reconstruction/list", name: '/order/reconstruction/list', component: () => import("../views/OrderReconstruction/index.vue") }, // 三维重建
      { path: "/order/result/list", name: '/order/result/list', component: () => import("../views/OrderResult/index.vue") }, // 重建结果审核
      { path: "/order/report/list", name: '/order/report/list', component: () => import("../views/OrderReport/index.vue") }, // 报表导出
      { path: "/combo/list", name: '/combo/list', component: () => import("../views/Combo/index.vue") }, // 套餐管理
      { path: "/hosp/list", name: '/hosp/list', component: () => import("../views/Hosp/index.vue") }, // 科室医院管理
      { path: "/publish/list", name: '/publish/list', component: () => import("../views/Publish/index.vue") }, // app管理
      { path: "/mnt/list", name: '/mnt/list', component: () => import("../views/Mnt/index.vue") }, // app管理
      { path: "/hosp/monitor/list", name: '/hosp/monitor/list', component: () => import("../views/monitor/index.vue") }, // 院内系统监控
      { path: "/discount/list", name: '/discount/list', component: () => import("../views/Discount/index.vue") }, // 优惠码
      { path: "/order/log", name: '/order/log', component: () => import("../views/OrderLlog/index.vue") }, // 操作日志
      { path: "/PersonalCenter", name: '/PersonalCenter', component: () => import("../views/PersonalCenter/index.vue") }, // 设置 个人中心
      { path: "/order/QRcode", name: '/order/QRcode', component: () => import("../views/QRcode/index.vue") }, // 二维码
      { path: "/test", component: Test },
    ]
  }
  // {
  //   path: '/login',
  //   name: 'Login',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  // }
]

const router = new VueRouter({
  routes
})

// 路由拦截
// router.beforeEach((to, from, next) => {
//   const token = getItem('token')
//   if (token) {
//     next()
//   } else {
//     if (to.path === '/login' || '/wechectlogin') return next()
//     next('/login')
//   }
// })

export default router
